<template>
    <div class="page page-one" style="min-height: 100vh;font-size: 18px">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('Google Authenticator')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel one-card">
                <div class="one-top">
                    <div>{{$t('Google身份验证器')}}</div>
                    <div><img src="../../assets/pictures/google@2x.png"></div>
                </div>
                <van-steps class="step-card" direction="vertical" :active="active">
                    <van-step>
                        <template #active-icon><div class="step-a">1</div></template>
                        <template #finish-icon><div class="step-a">1</div></template>
                        <template #inactive-icon><div class="step-b">1</div></template>
                        <div class="step-text">{{$t('下载验证器')}}</div>
                    </van-step>
                    <van-step>
                        <template #active-icon><div class="step-a">2</div></template>
                        <template #finish-icon><div class="step-a">2</div></template>
                        <template #inactive-icon><div class="step-b">2</div></template>
                        <div class="step-text">{{$t('扫描二维码')}}</div>
                    </van-step>
                    <van-step>
                        <template #active-icon><div class="step-a">3</div></template>
                        <template #finish-icon><div class="step-a">3</div></template>
                        <template #inactive-icon><div class="step-b">3</div></template>
                        <div class="step-text">{{$t('备份密钥')}}</div>
                    </van-step>
                    <van-step>
                        <template #active-icon><div class="step-a">4</div></template>
                        <template #finish-icon><div class="step-a">4</div></template>
                        <template #inactive-icon><div class="step-b">4</div></template>
                        <div class="step-text">{{$t('绑定验证器')}}</div>
                    </van-step>
                    <van-step>
                        <template #active-icon><div class="step-a">5</div></template>
                        <template #finish-icon><div class="step-a">5</div></template>
                        <template #inactive-icon><div class="step-b">5</div></template>
                        <div class="step-text">{{$t('绑定成功')}}</div>
                    </van-step>
                </van-steps>
            </div>
            <div class="shadow-lg bind-panel" v-if="active == 1">
                <div style="color: #cccccc;text-align: center;">
                    {{$t('请用Google身份验证器扫描二维码')}}
                </div>
                <div class="g-code-card">
                    <div class="qr-g-code" ref="gCode" id="gCode">
                    </div>
                </div>
                <div style="font-size: 14px;color: #ffffff;display: flex;justify-content: center" class="bs-form">
                    <van-field readonly v-model="code" style="border-radius: 10px">
                        <template #input><span style="font-weight: bold;font-size: 16px">{{code}}</span></template>
                        <template #button>
                            <div class="btn-copy1 cb1" style="color: #ffffff;padding-right: 10px" data-clipboard-action="copy" :data-clipboard-text="code" @click="copy('.cb1')">{{$t('复制')}}</div>
                        </template>
                    </van-field>
                </div>
                <div style="font-size:12px;color: #cccccc;text-align: center;padding: 10px 15px">
                    {{$t('如果您无法扫描这个二维码，请在Google身份验证器中手动输入这一串字符')}}
                </div>
                <div style="display: flex;justify-content: space-between;">
                    <van-button @click="onClickLeft" type="warning" style="width: 48%;">{{$t('上一步')}}</van-button>
                    <van-button type="primary" @click="goStep(2)" style="width: 48%">{{$t('下一步')}}</van-button>
                </div>
            </div>
            <div class="shadow-lg bind-panel" v-if="active == 2">
                <div style="color: #cccccc;text-align: center;">
                    {{$t('将密钥备份后保存在安全位置')}}
                </div>
                <div class="g-code-card">
                    <img src="../../assets/pictures/icon_key.png">
                </div>
                <div style="font-size: 14px;color: #ffffff;display: flex;justify-content: center">
                    <div>{{code}}</div>
                </div>
                <div style="font-size:12px;color: #cccccc;text-align: center;padding: 10px 30px">
                    {{$t('如果您丢失手机，此密钥将允许您回复您的身份验证器。否则，重置您的身份验证码需要联系客服。')}}
                </div>
                <div style="display: flex;justify-content: space-between;">
                    <van-button type="warning" @click="goStep(1)" style="width: 48%;">{{$t('上一步')}}</van-button>
                    <van-button type="primary" @click="goStep(3)" style="width: 48%">{{$t('下一步')}}</van-button>
                </div>
            </div>
            <div class="shadow-lg bind-panel" v-if="active == 3">
                <div style="color: #cccccc;text-align: center;">
                    {{$t('绑定Google身份验证器前需要验证您的账户')}}
                </div>
                <div style="margin-top: 20px;display: flex;justify-content: center;flex-direction: column;align-items: center" class="bs-form">
                    <van-field v-model="email" :placeholder="$t('请输入您的邮箱')">
                    </van-field>
                    <van-field v-model="v_email_code" :placeholder="$t('请输入您的邮箱验证码')">
                        <template #button>
                            <div v-if="timeout > 0" style="background-color: #2b3640;padding: 5px;color: #ffffff;min-width: 100px;text-align: center">{{timeout + 's'}}</div>
                            <div @click="send" v-else style="background-color: #ff5331;padding: 5px;color: #ffffff;min-width: 100px;text-align: center;border-radius:6px">{{$t('获取验证码')}}</div>
                        </template>
                    </van-field>
                    <van-field v-model="v_code" :placeholder="$t('请输入Google身份验证码')">
                    </van-field>
                </div>
                <div style="display: flex;justify-content: space-between;margin-top: 20px">
                    <van-button type="warning" @click="goStep(2)" style="width: 48%;">{{$t('上一步')}}</van-button>
                    <van-button type="primary" @click="setCode" style="width: 48%">{{$t('下一步')}}</van-button>
                </div>
            </div>
            <div class="one-card" v-if="active == 4">
                <div style="color: #cccccc;text-align: center;padding-top: 20px;font-size: 14px">
                    {{$t('Google身份验证器绑定成功')}}
                </div>
                <div style="padding: 20px 0 10px 0;text-align: center">
                    <img src="../../assets/pictures/pic_sel@2x.png">
                </div>
                <div style="text-align: center;padding-bottom: 20px">
                    <router-link to="/my">
                        <div style="color: #0184fa;margin-left: -10px">{{$t('返回个人中心')}}</div>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                active: 1,
                show: false,
                show4: false,
                code: '',
                qegcode: '',
                pay_password:'',
                scdisabled: false,
                email: '',
                v_email_code: '',
                v_code: '',
                timeout: 0,
                timeout_limit: 60,
                send_flag: false,
            }
        },
        methods: {
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("last_timer");
                        return;
                    }
                    this.timeout -= 1;
                    localStorage.setItem("last_timer", this.timeout);
                }, 1000);
            },
            send(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }
                let that = this;
                this.send_flag = true;
                this.$axios.post('/send_mail',
                    this.$qs.stringify({email: this.email})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        that.timerInit(this.timeout_limit);
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            goStep(val){
                this.active = val;
                if(this.active == 1){
                    setTimeout(() => {
                        this.genQrcode();
                    },500);
                }
            },
            onClickLeft() {
                this.url('/code-set')
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            setCode(){
                this.$toast.loading();

                if(this.send_flag){
                    return ;
                }

                if(this.email == ''){
                    this.$toast.fail(this.$t('请输入您的邮箱'));
                    return ;
                }
                if(this.v_email_code == ''){
                    this.$toast.fail(this.$t('请输入您的邮箱验证码'));
                    return ;
                }
                if(this.v_code == ''){
                    this.$toast.fail(this.$t('请输入Google身份验证码'));
                    return ;
                }
                let that = this;
                this.send_flag = true;
                let data = helper.encrypt(JSON.stringify({e:this.email, ve:this.v_email_code, c: this.v_code, gc:this.code}));
                this.$axios.post(this.$api_url.set_code,
                    this.$qs.stringify({data: data})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        that.active = 4;
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            genQrcode() {
                this.qegcode = new QRCode("gCode", {
                    text: this.code,
                    typeNumber: 17,
                    width: 128,
                    height: 128,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            isBind(){
                this.$axios.get('/gcode/is_bind').then((res) => {
                    if (res.data.code == 0) {
                        if(res.data.data.is_bind == 1){
                            this.url('/code-set');
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            }
        },
        mounted() {
            localStorage.setItem('aqzx', '1');
            this.isBind();
            this.$axios.get(this.$api_url.get_g_code).then((res) => {
                if (res.data.code == 0) {
                    this.code = res.data.data;
                    this.genQrcode();
                }
            }).catch((err) => {
                this.$toast.fail(err.message);
            });
        }

    }
</script>
